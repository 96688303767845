export const userGroupTypes = {
  functional: "functional",
  generic: "generic",
};

export const userGroupSelection = [
  {
    type: "Functional",
    value: userGroupTypes.functional,
  },
  {
    type: "Generic",
    value: userGroupTypes.generic,
  },
];
